<div
  class="form-group-container"
  [class.column]="column"
  [style.marginBottom]="marginBottom"
  [style.marginTop]="marginTop"
  [style.marginLeft]="marginLeft"
  [style.marginRight]="marginRight"
>
  <ng-content></ng-content>
</div>
